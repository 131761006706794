:root {
    --max-height: 60px;
    --max-width: 234px;
}

.containerMenufav {
    display: flex;
    /* justify-content: center; */
    flex-wrap: nowrap;
    list-style: none;
    overflow-x: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 0px;

    padding-top: 0px;
    padding-bottom: 0px;
    /* position: sticky; */
    /* top: 0px; */
    /* установка в JSX из body - ставить обязательно, т.к. по умолчанию прозрачный*/
    /* background-color: black; */
    -ms-overflow-style: none;  /* IE и Edge */
    scrollbar-width: none;  /* Firefox */
    justify-content: space-between;
    padding-left: 5px;

    height: var(--max-height);
    min-height: var(--max-height);
}

.containerMenufav::-webkit-scrollbar {
    display: none;
  }

.containerMenufav>UL {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    /* margin-left: 5px; */
    margin-right: 10px;
    /* left: 5px; */

    font-size: 14px;
    max-height: var(--max-height);
    /*  */
    max-width: var(--max-width);
    min-width: var(--max-width);
}

.containerMenufav>UL>div {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
}

.containerMenufav>UL>div>img {
    max-height: calc( var(--max-height));
    margin-right: 0px;
    /* border-radius: 7px; */
    border-radius: 10px;
}
