.ChoiceItem {
    padding-left : 5px!important;
    padding-right : 5px!important;
    padding-top : 5px!important;
    padding-bottom : 5px!important;
    text-align: center;
    /* white-space: 'pre-line'; */
    font-size: 10px!important;
    vertical-align: middle;

    display: table-cell;
}

.ChoiceItem > img {
    width: 50px;
}

.Selected {
    border-color: white!important;
}

.Selected  > img {
    opacity: 0.5!important;
}