.containerMenuchip {
    display: flex;
    /* justify-content: center; */
    flex-wrap: nowrap;
    list-style: none;
    overflow-x: auto;
    margin-top: 0px;
    margin-bottom: 0px;

    padding-top: 5px;
    padding-bottom: 5px;
    position: sticky;
    top: 0px;
    /* установка в JSX из body - ставить обязательно, т.к. по умолчанию прозрачный*/
    /* background-color: black; */
    -ms-overflow-style: none;  /* IE и Edge */
    scrollbar-width: none;  /* Firefox */
    z-index: 1;
}

.containerMenuchip::-webkit-scrollbar {
    display: none;
  }

.containerMenuchip>UL {
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    justify-content: center;
}

/* .containerMenuchip>UL>div {
    z-index: 1;
} */