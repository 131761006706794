.pagecontact {
    /* color: #61dafb; */
    padding-left: 0px;
}

.container {
    margin-left: 0px;
    padding-left: 0px;
}

.container > div {
    margin-bottom: 15px;
    /* padding-bottom: 5px; */
    /* padding-top: 5px;     */
    padding-left: 15px;
}