.pagelist {
    /* color: #61dafb; */
    display: flex;
    margin-left: 10px;
    justify-content: space-between;
}

.pagelist > h3 {
    margin-left: 15px;
}

.bottom{
    max-height: 60px;
    min-height: 60px;    
}