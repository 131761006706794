.containerListItems {
    /* display: flex; */
    /* justify-content: center; */
    /* flex-wrap: nowrap; */
    /* list-style: none; */
    /* overflow-x: auto; */
    /* margin-top: 5px; */
    /* margin-bottom: 5px; */

    /* padding-top: 5px; */
    /* padding-bottom: 5px; */
    padding-left: 10px;
    padding-right: 10px;
    /* position: sticky; */
    /* top: 0px; */
    /* установка в JSX из body - ставить обязательно, т.к. по умолчанию прозрачный*/
    /* background-color: black; */
    /* -ms-overflow-style: none;  IE и Edge */
    /* scrollbar-width: none;  Firefox */
}

/* .containerListItems::-webkit-scrollbar { */
    /* display: none; */
/* } */
/* Заголовок */
.containerListItems > div > h6 {
    /* text-align: center; */
    padding-left: 0px;
    padding-top: 8px;
}

.containerListItems > div > UL {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.containerListItems > div > UL > div {
    /* z-index: 0; */
    min-width: 100%;
    max-width: 100%;
}

.containerItemIMG {
    min-width: 35%;
    max-width: 35%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    flex-grow: 1;
}

.containerItemIMG>img {
    width: 100px;
    padding-left: 10px;
}

.containerItemIMG > span > span {
    top: 10%;
    transform: rotate(-20deg);
    z-index: 0;
    padding-bottom: 2px;
}

.containerItemTEXT {
    padding-top: 10px;
    flex-grow: 1;
}

.containerItemTEXT > div {
    padding-bottom: 0%!important;
    padding-left: 10px;
}

.containerItemTEXT > div > div {
    line-height: 1.5!important;
}

.containerItemTEXT > div > p {
    border-radius: 25px;
    margin-top: 3px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.containerItemTEXT > div > p > button {
    border-radius: 25px;      /* 25px 0*/
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 4px;
}