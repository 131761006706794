/* .header {
    grid-area: h;
    height: 200px;
    background-color: #290044;
}
  
.header img {
    width: 200px;
    height: 200px;
}

.loginBlock {
    float: right;
    color: #fdb83d;
    font-size: 28px;
    font-weight: bold;
    margin: 122px 80px 0 0;
}

.loginBlock a {
    text-decoration:none;
    color: #fdb83d;
    font-size: 28px;
}

.loginBlock a:hover {
    color: lightblue;
} */

/*  навигация */
.BottomNavigation {
    width: "100%";
    position: "fixed";
    bottom: "0px";
    text-align: "center";
    left: "0px";
}

.Grayscale {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    /* filter: url(grayscale.svg); Firefox 4+ */
    filter: gray; /* IE 6-9 */
}

.paperfooter, .paperfooter > div {
    overflow:hidden;
}