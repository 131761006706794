:root {
    --max-height90: 90px;
    --max-width120: 120px;
}

.containerMenufav {
    display: flex;
    /* justify-content: center; */
    flex-wrap: nowrap;
    list-style: none;
    overflow-x: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 0px;

    padding-top: 0px;
    padding-bottom: 0px;
    /* position: sticky; */
    /* top: 0px; */
    /* установка в JSX из body - ставить обязательно, т.к. по умолчанию прозрачный*/
    /* background-color: black; */
    -ms-overflow-style: none;  /* IE и Edge */
    scrollbar-width: none;  /* Firefox */
    justify-content: space-between;
    padding-left: 5px;

    height: var(--max-height90);
    min-height: var(--max-height90);
}

.containerMenufav::-webkit-scrollbar {
    display: none;
  }

.containerMenufav>UL {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 10px;
    /* margin-left: 5px; */
    /* left: 5px; */

    font-size: 14px;
    max-height: var(--max-height90);
    /*  */
    /* max-width: var(--max-width120); */
    /* min-width: var(--max-width120); */
}

.containerMenufav>UL>div {
    /* !!! */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    /* position: relative; */
    /* transform: translate(-50%, -50%); */
    /* top: 50%; */
    /* left: 50%; */
    /* background-size: cover; */

    z-index: 0;
}

.containerMenufav>UL>div>img {
    /* max-height: calc( var(--max-height90)); */
    margin-right: 0px;
    /* max-width: none; */
  
    /* min-width: auto; */
    /* min-height: auto; */
    /* border-radius: 10%; */
    height: var(--max-height90);
    /* height: auto; */
    /* position: absolute; */
    /* top: 50%; */
    /* left: 50%; */
    border-radius: 10px;
}
