.pageprofile {
    color: #61dafb;
    /* margin-left: 10px; */
}

.container {
    margin-left: 0px;
    padding-left: 0px;
}

.container > div {
    margin-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
}

.personalActions {
    padding-top: 25px;
    padding-bottom: 25px;
}

.loginOTPForm > div {
    /* padding-top: 10px; */
    margin-top: 10px;
    margin-bottom: 10px;
}
/* 
.loginOTPForm > button {

} */