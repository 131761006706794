:root {
  --offset-top : 1800px;
  --offset-left: 550px;
}
/*
padding-bottom: calc((100% - var(--main-img-width)) / 2); 
*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow:hidden; */
}


/* CSS для анимации ListItemsRow (добавление товара в корзину) */

@keyframes animation-css-frames {
  5% {
    transform: translate3d(0, 5px, 0);
  }
  10% {
    transform: translate3d(0, -10px, 0);
  }
  20% {
    transform: translate3d(0, -10px, 0);
  }
  to {
    opacity: 0.2;
    transform: translate3d(var(--offset-left), var(--offset-top), 0); 
    /* transform: translate3d(550px, 1800px, 0);  */
    /* 250 */
    /* transform: translate3d(500px, 1000px, 0); */
    /* transform: translate3d(100vw, 100vh, 0); */
  }
}