/* .pageprofile { */
    /* color: #61dafb; */
    /* margin-left: 10px; */
/* } */

.container > div {
    margin-left: 10px;
    margin-top: 10px;
    display: flex;
}

.container > div > button  {
    margin-left: 0px;
    margin-right: 8px;
}

form {
    width: 100%;
}

.bottom{
    max-height: 60px;
    min-height: 60px;    
}