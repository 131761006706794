

.containerItem {
    margin-left: 0px;
}

.Titlepagecart {
    color: #61dafb;
    /* margin-left: 10px; */
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
}

.TitlepagecartSecond {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
} 


.ListContainer {
    padding-left: 15px;
    padding-right: 15px;
    /* пока заглушка, но нужно расчитывать расстояние */
    /* margin-bottom: 160px; */
    /* height: 100%; */
    border-radius:0px;
    box-shadow:none!important;
}

.rowItemCart {
    /* корневой для списка в корзине */
    padding-left: 0px;
}

.BottomNavigation > div {
    width: "100%";
    position: "fixed";
    bottom: "0px";
    text-align: "center";
    left: "0px";

    background-color: coral!important;
    color: antiquewhite;
    z-index: 1;
}

.BottomNavigationD > div {
    width: "100%";
    position: "fixed";
    bottom: "0px";
    text-align: "center";
    left: "0px";

    background-color: rgb(64, 179, 49)!important;
    z-index: 1;
}

.BottonAction {
    width: 85%!important;
    min-width: 85%!important;
    border-radius: 50px!important;  
    /* background-color: palegoldenrod!important;   */
    font-weight:bolder;
}

.BottonAction > span {
    font-size: 20px;
}


.containerItemIMG {
    min-width: 25%;
    max-width: 25%;
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    flex-grow: 1;
}

.containerItemIMG>img {
    width: 85px;
}

.containerItemIMG  > span > span {
    top: 0%;
    right: 4%;
    transform: rotate(-20deg);
    /* font-size: 30px; */
    padding: 6%;
    border-radius: 20px;
}

.containerItemTEXT {
    /* padding-top: 10px; */
    flex-grow: 1;
    padding-right: 10px;
    padding-left: 10px;
}

.containerItemTEXT > div {
    padding-bottom: 0%!important;
    padding-left: 10px;
    padding-right: 10px;  
    display: flex;
    align-items: center;
}

.IncDecButton {
    border-style: groove;
    text-align: center;
    align-content: center;
    padding-left: 0px!important;
    padding-right: 0px!important;
    border-radius: 25px; /*15px 7px*/
    border-color: coral;
}

.IncDecButton > button {
    font-weight: bold;
    font-size: large;
    padding-left: 15px; /* 20px*/
    padding-right: 15px;
    /* уменьшаем, по умолчанию 8 */
    padding-top: 4px;
    padding-bottom: 4px;
    /* padding: 0px; */
}

.containerItemTEXT > div > span {
    font-weight: bold;
    font-size: large;
    margin-left: 5px;
    margin-right: 5px;    
}

.stuf {
    padding: 15px;
    /* padding-right: 15px; */
}

hr {
    margin-top: 4px;
    margin-bottom: 4px;
}

.containerBlancImg { 
    margin-top: 15%;
}

.containerBlancImg > img {
    width: 65%;
    margin-bottom: 10px;
    filter: sepia(70%) blur(2px);
}
