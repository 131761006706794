:root {
    --main-img-width: 86%;
    --all-padding: 5%;
}

.containerItem {
    margin-left: 0px;
}

.mainImage {
    /* transform: scale(1.5); */
    /* width: 86%; */
    width: var(--main-img-width);
}

.mainImageContainer {
    padding-top:  calc((100% - var(--main-img-width)) / 2);;
    padding-bottom: calc((100% - var(--main-img-width)) / 2);;
    vertical-align: middle;
    /* margin-bottom: 10px; */
    margin-top: 60px;
    text-align: center;
}

.mainImageContainer > span > span {
    top: 0%;
    right: 4%;
    transform: rotate(-20deg);
    font-size: 30px;
    padding: 6%;
    border-radius: 20px;
    padding-bottom: 18px;
}

.mainTitleContainer {
    font-size: 25px;
    padding-left: var(--all-padding);
}

.mainDesriptionContainer {
    padding-left: var(--all-padding);
    padding-right: var(--all-padding);
    white-space: pre-line;
    padding-top: 10px;
    padding-bottom: 10px;    
}

.mainDesriptionContainer > span:first-child {
    /* discription - пока вернул обычное вес для текста (кажется, что так лучше */
    /* font-weight: bold; */
    font-weight: normal;
}

.mainDesriptionContainer > span {
    /* composition */
    /* font-weight: bold; */
    font-size:small;
}

.mainСhoose {
    padding-left: var(--all-padding);
    padding-right: var(--all-padding);
    font-size: 13px;
    padding-top: 5px;
    padding-bottom: 5px;
}

/* .mainChoiceContainer {
    padding-left: var(--all-padding);
    padding-right: var(--all-padding);
} */

.BottomNavigation {
    width: "100%";
    position: "fixed";
    bottom: "0px";
    text-align: "center";
    left: "0px";

    padding-top: 5px;
    padding-bottom: 5px;
}

.BottonAction {
    /* width: 85%!important; */
    /* min-width: 85%!important; */
    border-radius: 10px!important;  
    margin-left: 5%!important;
    margin-right: 5%!important;
    border-radius: 10px!important;  
    /* background-color: palegoldenrod!important;   */
    font-weight:bolder!important;
}

.BottonAction > span {
    font-size: 23px;
    font-weight: bold;
}


.mainChoiceContainerEx{
    padding-left: var(--all-padding);
    padding-right: var(--all-padding);
    margin-bottom: 85px;
}

.mainChoiceContainerEx > div {
    padding-top: 10px;
    padding-bottom: 10px;
}

.fixBottomNavigation {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    height: 56px;
    background-color: #121212;
}