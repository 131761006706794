/* .App {
  text-align: center;
} */

/* .App-header { */
  /* вынесено в Theme */
  /* background-color: #282c34; */
  /* color: white; */  

  /* min-height: 100vh; */
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  /* font-size: calc(10px + 2vmin); */
/* } */

.app-wrapper__content {
  padding-left: 0px;
  padding-right: 0px;
}

/* снять все фильтры с элементов управления по умолчанию */
.Mui-selected {
  -webkit-filter: unset!important;
  -moz-filter: unset!important;
  -ms-filter: unset!important;
  -o-filter: unset!important;
  filter: unset!important;
  /* filter: url(grayscale.svg); Firefox 4+ */
  filter: unset!important; /* IE 6-9 */ 
}